import React, { useEffect } from "react";
import { useAppState } from "@state";
import { LazyMotion } from "framer-motion";
import clsx from "clsx";

import "../../css/fonts/fontsromero.css";

import Modal from "@organisms/Modal";
import RomeroHeader from "@organisms/RomeroHeader";
import Themer from "../atoms/Themer";

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};
const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children }) => {
  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);

  const [{ layout }] = useAppState();
  const { theme } = layout;
  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div
        id="app_layout"
        className={clsx("romero w-full transition duration-500", {
          "bg-cream": theme === "light",
          "bg-black": theme === "dark",
        })}
      >
        <RomeroHeader />
        {children}
        <Modal />
        <Themer />
        <div id="fb-root" />
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;

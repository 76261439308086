import React from "react";
import _brand from "@utils/brand";
import { HtmlParser } from "@base";
import romero from "../../../tailwind.romero.config";
import lakota from "../../../tailwind.lakota.config";
import lgca from "../../../tailwind.lgca.config";

const { brand } = _brand;

const styles = {
  romero: {
    // colors
    primary: romero.colors.blue.DEFAULT,
    secondary: romero.colors.blue.DEFAULT,
    accent: romero.colors.gold.DEFAULT,
    // fonts
    heading: romero.fontFamily.serif,
    body: romero.fontFamily.sans,
    control: romero.fontFamily.sans,
  },
  lakota: {
    primary: lakota.colors.red.DEFAULT,
    secondary: lakota.colors.red.DEFAULT,
    accent: lakota.colors.brown.DEFAULT,
    // fonts
    heading: lakota.fontFamily.serif,
    body: lakota.fontFamily.serif,
    control: lakota.fontFamily.sans,
  },
  lgca: {
    primary: lgca.colors.orange.DEFAULT,
    secondary: lgca.colors.blue.DEFAULT,
    accent: lgca.colors.blue.DEFAULT,
    // fonts
    heading: lgca.fontFamily.serif,
    body: lgca.fontFamily.sans,
    control: lgca.fontFamily.extended,
  },
}[brand];

const convertToRgb = hex => {
  if (hex) {
    const convertToNumber = s => {
      return parseInt(s, 16);
    };

    const h = hex.replace("#", "");
    const r = convertToNumber(h.substring(0, 2));
    const g = convertToNumber(h.substring(2, 4));
    const b = convertToNumber(h.substring(4, 6));
    return `${r} ${g} ${b}`;
  }
  return null;
};

// todo: fully implement themer

const Themer = () => {
  return (
    <>
      <HtmlParser
        html={`<style>
            :root {
              --color-primary: rgb(${convertToRgb(styles.primary)});
              --color-secondary: rgb(${convertToRgb(styles.secondary)});
              --color-accent: rgb(${convertToRgb(styles.accent)});

              --vm-player-theme: ${styles.primary};
              --vm-controls-border-radius: 999px;

              --font-heading: ${styles.heading.map(v => `"${v}"`).join(",")};
              --font-body: ${styles.body.map(v => `"${v}"`).join(",")};
              --font-control: ${styles.control.map(v => `"${v}"`).join(",")};
            }
          </style>`}
      />
      <div className="min-w-screen bg-background pointer-events-none fixed inset-0 z-[0] h-full min-h-screen w-full transition duration-500" />
    </>
  );
};

export default Themer;

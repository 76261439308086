import { useEffect, useState } from "react";
import { getCookie, setCookie } from "./cookies";

// generic functions
const removeParam = (parameter, url) => {
  // prefer to use l.search if you have a location/link object
  const urlparts = url.split("?");
  if (urlparts.length >= 2) {
    const prefix = `${encodeURIComponent(parameter)}=`;
    const pars = urlparts[1].split(/[&;]/g);

    // reverse iteration as may be destructive
    // eslint-disable-next-line no-plusplus
    for (let i = pars.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    const newUrl = `${urlparts[0]}?${pars.join("&")}`;
    return newUrl;
  }
  return url;
};

/* Activist Codes */

// stores + gets activist codes

const getRacParam = () => {
  if (typeof window !== "undefined") {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codes = urlParams.get("rac");
    if (codes) {
      // window.history.replaceState(
      //   {},
      //   document.title,
      //   removeParam("rac", window.location.href)
      // );
      const csv = decodeURIComponent(codes);
      const array = csv.split(",");
      return array;
    }
  }
  return [];
};

const getRacCookie = () => {
  try {
    const cookie = getCookie(`${process.env.GATSBY_APP_NAME}_rac`);
    if (typeof window !== "undefined") {
      if (cookie) {
        const data = JSON.parse(
          getCookie(`${process.env.GATSBY_APP_NAME}_rac`)
        );
        return data;
      }
    }
    return false;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return false;
  }
};

const setRacCookie = data => {
  setCookie(`${process.env.GATSBY_APP_NAME}_rac`, JSON.stringify(data));
};

/* Share Data */

// stores + gets action tracking codes / tweet text

const getAsCookie = () => {
  try {
    const cookie = getCookie(`${process.env.GATSBY_APP_NAME}_as`);
    if (typeof window !== "undefined") {
      if (cookie) {
        const data = JSON.parse(getCookie(`${process.env.GATSBY_APP_NAME}_as`));
        return data;
      }
    }
    return false;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return false;
  }
};

const setAsCookie = data => {
  setCookie(`${process.env.GATSBY_APP_NAME}_as`, JSON.stringify(data));
};

/* Share Codes */

export default {
  getRacCookie,
  setRacCookie,
  getAsCookie,
  setAsCookie,
  getRacParam,
};

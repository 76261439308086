import React from "react";
import brand from "@utils/brand";
import { HtmlParser } from "@base";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  inverse,
}) => {
  const { site } = brand;
  let Tag = "span";
  let classes = "";
  if (site.includes("lakota")) {
    switch (variant) {
      case "div":
        Tag = "div";
        classes = "";
        break;
      case "stat":
        Tag = "span";
        classes =
          "block font-serif font-bold text-6xl sm:text-8xl lg:text-10xl leading-none";
        break;
      case "statSub":
        Tag = "span";
        classes =
          "block font-serif font-bold text-2xl sm:text-4xl lg:text-5xl leading-less";
        break;
      case "h1":
        Tag = "h1";
        classes = "font-serif font-bold text-h1 leading-tighter";
        break;
      case "h2":
        Tag = "h2";
        classes = "font-serif font-bold text-3xl sm:text-h2 leading-tighter";
        break;
      case "h3":
        Tag = "h3";
        classes = "font-serif font-bold text-2xl sm:text-h3 leading-tighter";
        break;
      case "h4":
        Tag = "h4";
        classes =
          "font-serif text-xl md:text-2xl lg:text-h4 font-bold leading-tighter";
        break;
      case "h5":
        Tag = "h5";
        classes = "font-serif text-h4 sm:text-h5 font-bold leading-tighter";
        break;
      case "h6":
        Tag = "h6";
        classes = "font-serif text-xl sm:text-h6 leading-tighter";
        break;
      case "xl":
        classes = "block text-xl font-sans";
        break;
      case "lg":
        classes = "block text-lg font-sans";
        break;
      case "body":
        Tag = "div";
        classes = "block text-base text-lg leading-tight font-serif";
        break;
      case "body-sans":
        Tag = "div";
        classes = "block text-base font-sans leading-tight font-serif";
        break;
      case "blockquote":
        classes = "block text-2xl leading-tight font-serif font-bold";
        break;
      case "base":
        classes = "block text-base font-sans";
        break;
      case "sm":
        classes = "block text-sm font-sans";
        break;
      case "xs":
        classes = "block text-xs font-sans";
        break;
      case "xxs":
        classes = "block text-xxs font-sans";
        break;
      case "label":
        classes =
          "block text-xxs sm:text-xs leading-tighter font-sans tracking-0.5 text-red uppercase font-bold";
        break;
      case "body--small":
        classes = "block text-sm leading-normal font-sans";
        break;
      case "body--large":
        classes = "block text-lg sm:text-xl leading-normal font-serif";
        break;
      case "body--large-tight":
        classes = "block text-lg sm:text-xl leading-tight font-serif";
        break;
      case "body--xl-tight":
        classes = "block text-xl sm:text-1-5xl leading-tight font-serif";
        break;
      case "body--2xl-tight":
        classes = "block text-xl sm:text-2xl leading-tight font-serif";
        break;
      case "body--small-tight":
        classes = "block text-sm leading-tight font-sans";
        break;
      case "caption":
        classes = "block mt-4 text-sm leading-tighter italic text-gray";
        break;
      default:
      // do nothing
    }
  }
  if (site.includes("romero")) {
    switch (variant) {
      case "stat":
        Tag = "span";
        classes =
          "block font-serif font-medium text-6xl sm:text-8xl lg:text-10xl leading-none";
        break;
      case "statSub":
        Tag = "span";
        classes =
          "block font-serif font-medium text-2xl sm:text-4xl lg:text-5xl leading-less";
        break;
      case "h1":
        Tag = "h1";
        classes = "font-serif text-3xl sm:text-6xl leading-tight";
        break;
      case "h1--highlight":
        Tag = "h1";
        classes = "font-serif text-[3.375rem] sm:text-[4.375rem] leading-tight";
        break;
      case "h2":
        Tag = "h2";
        classes = "font-serif text-3xl sm:text-5xl leading-tighter";
        break;
      case "h2--highlight":
        Tag = "h2";
        classes =
          "font-serif text-[2.875rem] sm:text-[3.375rem] leading-tighter";
        break;
      case "h3":
        Tag = "h3";
        classes = "font-serif text-3xl sm:text-4xl leading-tighter";
        break;
      case "h3--highlight":
        Tag = "h3";
        classes =
          "font-serif text-[2.375rem] sm:text-[2.875rem] leading-tighter";
        break;
      case "h4":
        Tag = "h4";
        classes = "font-serif text-2xl sm:text-3xl leading-tight";
        break;
      case "h5":
        Tag = "h5";
        classes = "font-serif text-2xl leading-tight";
        break;
      case "h6":
        Tag = "h6";
        classes = "font-serif text-xl leading-tight";
        break;
      case "xl":
        classes = "block text-xl font-sans";
        break;
      case "lg":
        classes = "block text-lg font-sans";
        break;
      case "blockquote":
        classes = "block text-2xl leading-snug font-serif italic";
        break;
      case "pullquote":
        classes = "block text-smaller leading-normal";
        break;
      case "quoted":
        classes = "inline-block text-sm font-serif";
        break;
      case "sm":
        classes = "block text-sm font-sans";
        break;
      case "smaller":
        classes = "block text-smaller font-sans";
        break;
      case "xs":
        classes = "block text-xs font-sans";
        break;
      case "xxs":
        classes = "block text-xxs font-sans";
        break;
      case "label":
        classes =
          "block text-xs leading-tighter font-sans tracking-widest text-blue uppercase font-medium";
        break;
      case "body":
        classes = "block text-base sm:text-lg leading-relaxed font-sans";
        break;
      case "body--base":
        classes = "block text-base leading-relaxed font-sans";
        break;
      case "body--xsmall":
        classes = "block text-xs leading-relaxed font-sans";
        break;
      case "body--small":
        classes = "block text-sm leading-relaxed font-sans";
        break;
      case "body--small-tight":
        classes = "block text-sm leading-none font-sans";
        break;
      case "body--large":
        classes = "block text-lg sm:text-xl leading-relaxed font-sans";
        break;
      case "body--large-tight":
        classes = "block text-lg sm:text-xl leading-tight font-sans";
        break;
      case "body--xl":
        classes = "block text-xl sm:text-1-5xl leading-relaxed font-sans";
        break;
      case "body--xl-tight":
        classes = "block text-xl sm:text-1-5xl leading-tight font-sans";
        break;
      case "body--2xl-tight":
        classes = "block text-1-5xl sm:text-2xl leading-tight font-sans";
        break;
      case "caption":
        classes = "inline-block text-xxs md:text-xs leading-normal italic";
        break;
      case "list":
        classes = "text-1-5xl leading-tight sm:text-2xl font-serif";
        break;
      default:
        break;
      // do nothing
    }
  }
  if (site.includes("lgca")) {
    switch (variant) {
      case "stat":
        Tag = "span";
        classes =
          "block font-serif font-bold text-4xl sm:text-6xl lg:text-8xl leading-none";
        break;
      case "statSub":
        Tag = "span";
        classes =
          "block font-serif font-bold text-xl font-extended sm:text-2xl lg:text-3xl leading-less";
        break;
      case "h1":
        Tag = "h1";
        classes = "font-serif font-bold text-h1 leading-tighter";
        break;
      case "h2":
        Tag = "h2";
        classes = "font-serif font-bold text-3xl sm:text-h2 leading-tighter";
        break;
      case "h2-blog":
        Tag = "h2";
        classes =
          "font-serif font-bold text-3xl md:text-4xl lg:text-5xl leading-tighter";
        break;
      case "h3":
        Tag = "h3";
        classes = "font-serif font-bold text-2xl sm:text-h3 leading-tighter";
        break;
      case "h4":
        Tag = "h4";
        classes =
          "font-serif text-xl md:text-2xl lg:text-h4 font-bold leading-tighter";
        break;
      case "h5":
        Tag = "h5";
        classes = "font-serif text-h5 sm:text-h4 font-bold leading-tighter";
        break;
      case "h6":
        Tag = "h6";
        classes = "font-extended text-xl sm:text-h6 leading-tighter";
        break;
      case "xl":
        classes = "block text-xl font-sans";
        break;
      case "lg":
        classes = "block text-lg font-sans";
        break;
      case "body":
        classes = "block text-base leading-tight font-sans";
        break;
      case "body-sans":
        classes = "block text-base font-sans leading-tight font-sans";
        break;
      case "blockquote":
        classes = "block text-2xl leading-tight font-sans font-bold";
        break;
      case "base":
        classes = "block text-base font-sans";
        break;
      case "sm":
        classes = "block text-sm font-sans";
        break;
      case "xs":
        classes = "block text-xs font-sans";
        break;
      case "xxs":
        classes = "block text-xxs font-sans";
        break;
      case "label":
        classes =
          "block text-xxs sm:text-xs leading-tighter font-sans tracking-0.5 uppercase font-bold";
        break;
      case "label--lg":
        classes =
          "block text-sm leading-tighter font-sans tracking-0.5 uppercase font-bold";
        break;
      case "body--small":
        classes = "block text-sm leading-normal font-sans";
        break;
      case "body--large":
        classes = "block text-lg sm:text-xl leading-normal font-extended";
        break;
      case "body--large-tight":
        classes = "block text-lg sm:text-xl leading-tight font-extended";
        break;
      case "body--large-tight-cb":
        classes = "block text-lg sm:text-xl leading-tight font-sans";
        break;
      case "body--xl-tight":
        classes = "block text-xl sm:text-1-5xl leading-tight font-extended";
        break;
      case "body--2xl-tight":
        classes = "block text-xl sm:text-2xl leading-tight font-extended";
        break;
      case "body--small-tight":
        classes = "block text-sm leading-tight font-sans";
        break;
      case "caption":
        classes = "block mt-4 text-sm leading-tighter italic text-gray";
        break;
      case "citation":
        classes = "font-extended font-bold uppercase mt-6 opacity-0";
        break;
      default:
    }
  }
  return React.Children.toArray(children).map((child, i) => {
    // const components = [RichText, Phone];
    // if children are not components (html or string), then render the html
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={`
            ${classes} 
            ${_className} 
            ${inverse && richText ? "rich-text--inverse" : ""}
            ${!richText ? "" : "rich-text"} 
            ${quotes ? "quotes" : ""} 
            `}
        >
          <HtmlParser html={child} />
        </Tag>
      );
    }
    // otherwise return children
    return (
      <Tag
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className={`
          ${classes} 
          ${_className} 
          ${!richText ? "" : "rich-text"} 
          ${quotes ? "quotes" : ""} 
        `}
      >
        {child}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "span",
  className: null,
  variant: null,
  richText: true,
  quotes: false,
  children: null,
};

export default Text;

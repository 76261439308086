import React, { useEffect, useState, useMemo } from "react";
import "@css/at.css";
import { m } from "framer-motion";
// todo: fix file structure
import Progress from "@molecules/Progress";
import clsx from "clsx";
import getParam from "@utils/getParam";

// UTILS
import {
  setCustomFrequencies,
  setAmounts,
  selectAmount,
  selectFreq,
} from "@atUtils";
import _brand from "@utils/brand";
import Icon from "./Icon";

const frequencies = {
  0: "once",
  4: "monthly",
  6: "annual",
};

const { romero } = _brand;

// * Initiate Action Tag
const initAt = () => {
  // init datalayer
  window.dataLayer = window.dataLayer || [];
  // create nvtag_callbacks property
  window.nvtag_callbacks = window.nvtag_callbacks || {};
};

// * Remove existing tag data
const resetAt = () => {
  // remove existing nv instance
  if ("nvtag" in window) {
    // eslint-disable-next-line no-console
    console.log("Resetting ActionTag...");
    document.documentElement.removeAttribute("class");
    delete window.nvtag;
    delete window.nvtag_callbacks;
    delete window.nvtag_plugins;
    document.getElementById("attagscript").remove();
  }
  //
  if (!document.getElementById("attagscript")) {
    // eslint-disable-next-line no-console
    console.log("Loading ActionTag...");
    const actionTag = document.createElement("script");
    actionTag.setAttribute("id", "attagscript");
    actionTag.setAttribute("src", process.env.GATSBY_AT_JS);
    document.body.appendChild(actionTag);
  }
};

const removeAtStyles = mutationsList => {
  mutationsList.forEach(mut => {
    mut.addedNodes.forEach(n => {
      if (n.getAttribute) {
        if (
          n.getAttribute("href") &&
          (n.getAttribute("href").includes("at.min.css") ||
            n.getAttribute("href").includes("extra.min.css"))
        ) {
          n.remove();
        }
      }
    });
  });
};

const createMutationObserver = () => {
  // watch for at styles and remove them
  const observer = new MutationObserver(removeAtStyles);
  observer.observe(document.head, {
    attributes: false,
    childList: true,
    subtree: true,
  });
  // remove observer upon exit
  return observer;
};

const updateStyles = rawData => {
  const {
    IsRecurring,
    SelectedFrequency,
    CustomFrequency,
    SelectAmount,
    InHonorOrInMemoryOf,
  } = rawData;

  const data = {};

  if (IsRecurring === true) {
    data.frequency = "monthly";
  } else {
    data.frequency = "once";
  }

  if (rawData["IsRecurring.Value"]) {
    if (rawData["IsRecurring.Value"] === "true") {
      data.frequency = "monthly";
    } else {
      data.frequency = "once";
    }
  }

  // update frequencies
  if (SelectedFrequency) {
    // add / remove classes from the native frequency selections
    const els = document.querySelectorAll(".form-item-selectedfrequency label");
    els.forEach(el => {
      const input = el.querySelector("input");
      if (input.value === SelectedFrequency) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    });
    data.frequency = frequencies[SelectedFrequency];
  }

  if (CustomFrequency) {
    // add / remove classes from the custom frequency selections
    const elsAlt = document.querySelectorAll(
      ".at-recurring label.label-amount"
    );
    elsAlt.forEach(el => {
      const input = el.querySelector("input");
      if (input.value === CustomFrequency) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    });
    data.frequency = frequencies[CustomFrequency];
    // set value of native frequency
    const sf = document.getElementsByName("SelectedFrequency")[0];
    if (sf) {
      sf.value = CustomFrequency;
    }
  }

  // update honor/memory
  if (InHonorOrInMemoryOf) {
    // add / remove classes from the native frequency selections
    const els = document.querySelectorAll(
      ".form-item-inhonororinmemoryof label"
    );
    els.forEach(el => {
      const input = el.querySelector("input");
      if (input) {
        if (input.value === InHonorOrInMemoryOf) {
          el.classList.add("active");
        } else {
          el.classList.remove("active");
        }
      }
    });
    data.inhonorOf = InHonorOrInMemoryOf;
  }

  // update amounts
  if (SelectAmount) {
    const amount = parseFloat(SelectAmount).toFixed(2);
    data.amount = amount;
    const els = document.querySelectorAll(".SelectAmount .label-amount");
    els.forEach(el => {
      const input = el.querySelector("input");
      if (input.value === SelectAmount) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    });
  }
  return data;
};

// COMPONENT

const AtForm = ({
  formId: _formId,
  short,
  actionType,
  actionDispatch,
  actionState,
  callbacks = {},
}) => {
  const [error, setError] = useState("");
  const segments = _formId?.split("/");
  const formId =
    segments && _formId?.search("http") !== -1
      ? segments[(segments?.length || 0) - 1] ||
        segments[(segments?.length || 0) - 2]
      : _formId; // config

  const { frequency, level, visibleAmounts, levels, amount, amountIdx, total } =
    actionState;

  const formUrl = useMemo(
    () =>
      actionType === "advocacy"
        ? `https://advocator.ngpvan.com/https%3a%2f%2fsecure.everyaction.com%2fv1%2fForms%2f${formId}/ngpForm`
        : `https://secure.everyaction.com/v1/Forms/${formId}`,
    [formId]
  );

  const [progress, setProgress] = useState({});
  const [heading, setHeading] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const [freqLoaded, setFreqLoaded] = useState(false);
  const [formData, setFormData] = useState({});
  const [ngpData, setNgpData] = useState({});
  const [hasMeter, setHasMeter] = useState(false);
  const [initCustom, setInitCustom] = useState(false);
  const [frequencyPhrase, setFrequencyPhrase] = useState(false);

  // * General Form Logic

  const getFormData = () => {
    const form = document.querySelector(".at-form form");
    if (form) {
      const data = new FormData(form);
      const formValues = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [k, v] of data.entries()) {
        formValues[k] = v;
      }
      if (document.getElementsByName("IsRecurring")[0]) {
        formValues.IsRecurring =
          document.getElementsByName("IsRecurring")[0].checked;
      }
      setFormData(d => ({ ...d, ...formValues }));
    }
  };

  // add callbacks

  useEffect(() => {
    let observer;
    setTimeout(() => {
      observer = createMutationObserver();
      resetAt();
      initAt();

      // update formdata & ngp data each time there is a callback
      [
        "alterErrors",
        "alterFill",
        "alterFormDefinition",
        "alterPost",
        "alterRequireValid",
        "onSubmit",
        "postFill",
        "postContributionAmountChanged",
        "postRender",
        "preSegue",
        "segue",
      ].forEach(c => {
        window.nvtag_callbacks[c] = window.nvtag_callbacks[c] || [];
        window.nvtag_callbacks[c].push(e => {
          if (callbacks[c]) {
            callbacks[c](e);
          }
          if (c === "postFill") {
            getFormData();
            setTimeout(() => {
              setFormReady(true);
            }, 500);
          }
          if (c === "postContributionAmountChanged") {
            actionDispatch({
              type: "setAmount",
              amount: e.baseAmount,
              total: e.totalAmount,
            });
          }
          setNgpData(d => ({ ...d, ...e, lastCallback: c }));
        });
      });
    }, 500);
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  // ngpData listener
  useEffect(() => {
    // listen to form changes here
    const { form_definition } = ngpData;
    if (form_definition) {
      setHeading(form_definition.title);
    }
    const meter = form_definition?.form_elements.find(
      el => el.name === "MeterHtml" && el.type === "markup" && el.markup
    );
    if (meter) {
      setHasMeter(true);
    }
    if (ngpData.thank === true) {
      actionDispatch({ type: "setStatus", status: "submitted" });
      if (form_definition?.response?.trackingId) {
        actionDispatch({
          type: "setTrackingId",
          trackingId: form_definition.response.trackingId,
        });
      }
    }
  }, [ngpData]);

  // add event listeners
  useEffect(() => {
    let formEl;
    let formClicklistener;
    let formInputlistener;
    if (formReady) {
      formEl = document.querySelector(".ngp-form form");
      formClicklistener = formEl.addEventListener("click", () => getFormData());
      formInputlistener = formEl.addEventListener("input", () => getFormData());
    }

    return () => {
      try {
        if (formEl) {
          formEl.removeEventListener("click", formClicklistener);
          formEl.removeEventListener("input", formInputlistener);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    };
  }, [formReady]);

  // get form progress
  useEffect(() => {
    if (hasMeter) {
      setProgress(true);
      fetch(`https://secure.everyaction.com/v2/forms/${formId}/progress`)
        .then(res => res.json())
        .then(res => {
          setProgress(res);
        });
    }
  }, [hasMeter]);

  // formData listener
  useEffect(() => {
    if (formReady && (!levels || (!!levels && freqLoaded))) {
      // listen to form changes here
      const data = updateStyles(formData);
      if (frequency !== data.frequency) {
        actionDispatch({
          type: "setFrequency",
          frequency: data.frequency,
        });
      }
    }
  }, [formData, frequency]);

  // set frequency phrase
  useEffect(() => {
    let phrase = "once";
    if (frequency === "monthly") {
      phrase = "each month";
    }
    if (frequency === "annual") {
      phrase = "each year";
    }
    setFrequencyPhrase(phrase);
    // wait for repaint
    requestAnimationFrame(() => {
      // eslint-disable-next-line func-names
      requestAnimationFrame(function () {
        getFormData();
      });
    });
  }, [levels, level, frequency, formReady]);

  // * MEMBERSHIP

  // set error if membership level is too low
  useEffect(() => {
    if (!!levels && freqLoaded) {
      if (amount < visibleAmounts[0] && actionState.activeLevel === 0) {
        setError(`Amount must be at least $${visibleAmounts[0]}`);
      } else {
        setError(false);
      }
    }
  }, [amount]);

  // set visible amounts when they change
  useEffect(() => {
    if (formReady && initCustom && freqLoaded) {
      if (visibleAmounts) {
        setAmounts(visibleAmounts, amountIdx, amount);
      }
    }
  }, [visibleAmounts, formReady]);

  // set custom intial custom amounts && frequency buttons
  useEffect(() => {
    if (formReady) {
      if (!initCustom) {
        if (visibleAmounts) {
          setAmounts(visibleAmounts);
        }
        setCustomFrequencies();
        setInitCustom(true);
        getFormData();
      }
    }
  }, [formReady]);

  // load initial form frequency from params
  useEffect(() => {
    const initialAmount = getParam("amount");
    const initialFreq = getParam("freq");
    if (formReady && !!levels && !freqLoaded && initCustom) {
      // set initial amounts
      if (initialFreq && ["annual", "monthly"].includes(initialFreq)) {
        selectFreq(initialFreq);
      } else if (romero) {
        selectFreq("annual");
      } else {
        selectFreq("monthly");
      }
      getFormData();
      setFreqLoaded(true);
      if (initialAmount && levels) {
        actionDispatch({
          type: "setAmount",
          frequency: initialFreq || (romero ? "annual" : "monthly"),
          amount: parseInt(initialAmount, 10),
          total: parseInt(initialAmount, 10),
        });
      }
      if (!initialAmount && levels) {
        selectAmount(visibleAmounts[0]);
      }
      requestAnimationFrame(() => {
        if (initialAmount) {
          // eslint-disable-next-line func-names
          requestAnimationFrame(function () {
            selectAmount(initialAmount);
            getFormData();
          });
        }
      });
    }
    if (!levels) {
      if (initialFreq) {
        selectFreq(initialFreq);
      } else if (romero) {
        // selectFreq("annual");
      } else {
        // selectFreq("monthly");
      }
      getFormData();
      requestAnimationFrame(() => {
        if (initialAmount) {
          // eslint-disable-next-line func-names
          requestAnimationFrame(function () {
            selectAmount(initialAmount);
            getFormData();
          });
        }
      });
    }
  }, [formReady, initCustom]);

  return (
    <m.div
      className={clsx("relative w-full", {
        "min-h-[33vh]": !short,
        "opacity-0": actionState.status === "submitted" && !short,
      })}
    >
      <m.div
        initial={{ opacity: 0, scale: 0 }}
        className="absolute inset-0 flex items-center justify-center"
        animate={
          formReady ? { opacity: 0, scale: 0 } : { opacity: 1, scale: 1 }
        }
      >
        <Icon
          name="loading"
          className="h-8 w-8 animate-spin rounded-full bg-white p-1 text-primary"
        />
      </m.div>
      <div
        key={formId}
        className={clsx("w-full transition duration-300", {
          "bg-white text-black shadow-xl": !short,
          "opacity-0": !formReady,
          "mb-6": !short,
        })}
      >
        {!short && heading && (
          <h2 className="p-6 pb-3 text-center font-heading text-xl text-black md:text-3xl">
            {heading}
          </h2>
        )}
        {error && (
          <p className="my-3 w-full text-center text-sm font-bold uppercase text-red">
            {error}
          </p>
        )}
        {hasMeter && progress && typeof progress !== "boolean" && (
          <Progress {...progress} />
        )}
        <div
          id="formContainer"
          key="formContainer"
          className="ngp-form px-3 pb-6 pt-3 sm:px-6"
          data-form-url={formUrl}
          data-fastaction-endpoint="https://fastaction.ngpvan.com"
          data-inline-errors="true"
          data-fastaction-nologin="true"
          data-databag-endpoint="https://profile.ngpvan.com"
          data-databag="everybody"
          data-mobile-autofocus="false"
        />
        {!!total && frequencyPhrase && (
          <div className="border-t border-black bg-white px-6 py-3 text-center text-sm font-bold text-secondary">{`Total Contribution: $${total.toFixed(
            2
          )} ${frequencyPhrase}.`}</div>
        )}
      </div>
    </m.div>
  );
};

AtForm.defaultProps = {
  formId: null,
  activistCodes: null,
  gdprEnabled: false,
};

export default AtForm;

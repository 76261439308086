import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { useAppState } from "@state";

import { Link } from "gatsby";

const AppLink = ({
  to,
  href,
  title,
  className: _className,
  children,
  onClick,
  activeClassName,
  style,
  partiallyActive,
  onMouseEnter,
  onMouseLeave,
  tag: Tag,
  external,
}) => {
  const [, dispatch] = useAppState();
  const _url = to || href;

  if (_url && typeof _url === "string") {
    const craftUrl = process.env.GATSBY_CRAFT_BASE_URL;
    const siteUrl = process.env.GATSBY_SITE_URL;
    let url = (
      !external ? _url.replace(craftUrl, "").replace(siteUrl, "") : _url
    ).split("?")[0];

    const params = _url.split("?")[1] || "";
    let searchParams = params.split("&").reduce((agg, p) => {
      const [k, v] = p.split("=");
      // eslint-disable-next-line no-param-reassign
      agg[k] = v;
      return agg;
    }, {});

    // todo: replace with URL methods
    const addParams = string => {
      if (typeof window !== "undefined") {
        searchParams = {
          ...(searchParams || {}),
          ...window.location.search
            .replace("?", "")
            .split("&")
            .reduce((agg, p) => {
              const [k, v] = p.split("=");
              // eslint-disable-next-line no-param-reassign
              agg[k] = v;
              return agg;
            }, {}),
        };
      }
      if (Object.values(searchParams).filter(v => v).length) {
        return `${string}?${Object.entries(searchParams)
          .filter(([k, v]) => k && v)
          .map(([k, v]) => `${k}=${v}`)
          .join("&")}`;
      }
      return string;
    };

    const handleClick = e => {
      // e.preventDefault();
      if (onClick) {
        onClick(e);
      }

      dispatch({
        type: "closeModal",
      });

      dispatch({
        type: "closeNav",
      });

      // Trigger page transition
      dispatch({
        type: "startTransition",
        to: url,
      });
    };

    // handle internal links
    // usually internal router and/or transition
    if (
      url.includes(craftUrl) ||
      url.includes(siteUrl) ||
      (!url.includes("https://") &&
        !url.includes("http://") &&
        !url.includes("mailto:") &&
        !url.includes("tel:"))
    ) {
      // Add a preceding slash to any relative urls
      if (url.indexOf("http") === -1 && url.charAt(0) !== "/") {
        url = `/${url}`;
      }
      return (
        <Link
          className={clsx("cursor-pointer", _className)}
          // activeStyle={{ cursor: "default" }}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          title={title}
          name={title}
          to={addParams(url)}
          onClick={handleClick}
          style={style}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </Link>
      );
    }
    if (url.includes(process.env.GATSBY_OTHER_SITE)) {
      return (
        <a
          href={addParams(url)}
          title={title}
          name={title}
          className={clsx("cursor-pointer", _className)}
          onClick={onClick}
          style={style}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </a>
      );
    }
    // handle external links
    // usually open in new tab, noreferer, noopener
    return (
      <a
        href={
          addParams(url)
            .replace(process.env.GATSBY_ASSET_ROOT, "/assets/")
            .replace(process.env.GATSBY_DOWNLOADS_ROOT, "/downloads/") || ""
        }
        title={title}
        name={title}
        target="_blank"
        rel="noopener noreferrer"
        className={clsx("cursor-pointer", _className)}
        onClick={onClick}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </a>
    );
  }

  if (Tag) {
    return (
      <Tag
        onClick={onClick}
        className={_className}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </Tag>
    );
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx("cursor-pointer text-left", _className)}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
};

AppLink.defaultProps = {
  to: null,
  title: null,
  className: null,
  onClick: null,
  activeClassName: null,
  partiallyActive: true,
};

export default AppLink;

import React from "react";
import "./src/css/index.css";
import { AnimatePresence, MotionConfig } from "framer-motion";
import { AppStateProvider } from "@state";
import brand from "@utils/brand";

import LakotaLayout from "@templates/LakotaLayout";
import LakotaAcLayout from "@templates/LakotaAcLayout";
import LakotaGcLayout from "@templates/LakotaGcLayout";
import LgcaAcLayout from "@templates/LgcaAcLayout";
import LgcaLayout from "@templates/LgcaLayout";
import RomeroLayout from "@templates/RomeroLayout";
import Userback from "@organisms/Userback";

const { site } = brand;
export const wrapRootElement = ({ element }) => {
  return <AppStateProvider>{element}</AppStateProvider>;
};

const Default = {
  lakota: LakotaLayout,
  lakotaAc: LakotaAcLayout,
  lakotaGc: LakotaGcLayout,
  romero: RomeroLayout,
  lgcaAc: LgcaAcLayout,
  lgca: LgcaLayout,
}[site];

export const wrapPageElement = ({ element, props }) => {
  const { location, pageContext } = props;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      {process.env.GATSBY_USERBACK_TOKEN && <Userback />}
      <Default location={location} pageContext={pageContext}>
        <MotionConfig transition={{ duration: 0.3, type: "tween" }}>
          <AnimatePresence
            mode={site === "lgca" ? "sync" : "wait"}
            initial={false}
          >
            {element}
          </AnimatePresence>
        </MotionConfig>
      </Default>
    </>
  );
};

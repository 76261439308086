"use client";

import React from "react";
import parse, { domToReact } from "html-react-parser";
import { Script } from "gatsby";
import AppLink from "./AppLink";

// todo: add manual script cleanup

const defaults = {
  // ? this function automatically handles inconsistent returns by design
  // eslint-disable-next-line consistent-return
  replace: domNode => {
    switch (domNode.name) {
      case "script":
        // if (typeof window !== "undefined") {
        //   if (domNode.attribs.src.includes("buzzsprout.com")) {
        //     if (window?.renderBuzzsproutPlayerHTML) {
        //       const iframes = document.getElementsByTagName("iframe");
        //       const hasIframe = Array.from(iframes).some(iframe =>
        //         iframe.src.includes("buzzsprout.com")
        //       );
        //       if (!hasIframe) {
        //         try {
        //           return <>{parse(window.renderBuzzsproutPlayerHTML())}</>;
        //         } catch (e) {
        //           // eslint-disable-next-line no-console
        //           console.log(e);
        //         }
        //       }
        //     }
        //   }
        // }
        return <Script src={domNode.attribs.src} />;
      case "a":
        return (
          <AppLink {...domNode.attribs}>
            {domToReact(domNode.children, defaults)}
          </AppLink>
        );
      default:
        break;
    }
  },
};

const Html = ({ html, options = {} }) => {
  if (html) return <>{parse(html, { ...defaults, ...options })}</>;
  return null;
};

export default Html;
